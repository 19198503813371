<template>
  <div>
    <div class="d-flex gap-20 mb-5">
      <v-card
        @click="videoDialog = true"
        width="250"
        height="170"
        class="pa-5 d-flex flex-column"
        outlined
        hover
      >
        <v-icon size="45" color="accent">mdi-play-circle</v-icon>
        <div class="text-center font-weight-bold mb-2">
          Intro for Promo Recipe eBook & Client List
        </div>
        <div class="text-center caption" :style="{ lineHeight: '1.1' }">
          If this is the first time you are creating a recipe eBook, please watch this
          demo
        </div>
      </v-card>
    </div>
    <v-row>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>Promo Recipe eBook 1</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-lg-nowrap">
                <div class="col-12 col-lg-11 px-0">
                  <v-card class="my-0 my-md-5 my-lg-5 box-shadow primary pa-1 pa-md-5 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Ebook Title Page: </span>
                    <span class="ribbon-button"
                      v-if="lgUp"
                    >
                      <v-btn
                        class="mt-2"
                        @click="copyRecipeLink(1, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Client Questionnaire Link for Promo Recipe eBook 1
                      </v-btn>
                    </span>
                    <span class="ribbon-button-sm" v-else>
                      <v-btn
                        class="mt-2 mr-5"
                        @click="copyRecipeLink(1, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Client Link
                      </v-btn>
                    </span>
                    <div class="d-flex gap-1 align-start justify-start justify-md-center justify-lg-center flex-wrap">
                      <v-chip
                        class="ma-1 ma-lg-2"
                        label
                        style="background-color: #463f57; color: #fff"
                      >
                        Business Name
                      </v-chip>
                      <v-text-field
                        class="mr-2"
                        v-model="promoData.businessName"
                        outlined
                        dense
                        background-color="white"
                      >
                      </v-text-field>
                      <v-btn
                        depressed
                        icon
                        @click="
                          () => {
                            previewTitleDialog = true;
                            itemToShow = 'pageTitle';
                          }
                        "
                        :disabled="!promoData?.businessName || !promoData.bio"
                      >
                        <v-icon v-if="mdUp" large style="color: #463f57"> mdi-eye </v-icon>
                      </v-btn>
                    </div>
                    <v-chip
                      class="ma-2"
                      label
                      style="background-color: #463f57; color: #fff"
                    >
                      Page 1 of Promo eBook
                    </v-chip>
                    <div style="background-color: white">
                      <vue-editor
                        v-model="promoData.bio"
                        background-color="white"
                        placeholder="(500 Character Limit)"
                        class="mb-3"
                      />
                    </div>
                  </v-card>
                </div>
                <!-- <div class="col-12 col-md-4 col-lg-2">
        <v-card
          class="my-5 box-shadow primary pa-5 ribbon-container text-center"
          outlined
        >
          <span class="ribbon-name">QR Code: </span>
          <div>
            <qrcode-vue :value="generatedLink" :size="size" level="M" id="qr-code" />
          </div>
          <v-btn @click="printQR" x-small depressed color="info"> Download </v-btn>
        </v-card>
      </div> -->
              </div>
              <div class="d-flex gap-1 px-0 px-md-5 px-lg-8 align-start flex-sm-wrap flex-lg-nowrap">
                <div class="col-12 col-md-10 col-lg-11 col-xl-10">
                  <div
                    class="d-flex flex-wrap flex-xl-nowrap align-start justify-center gap-3"
                  >
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">Header Call-To-Action:</span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button Name
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData.headerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button Link
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData.headerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button On/Off
                              <v-switch
                                v-model="promoData.headerButton"
                                color="primary"
                                class="ml-3"
                              ></v-switch>
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button Color
                              <input
                                type="color"
                                id="head"
                                name="header button color"
                                class="ml-3"
                                v-model="promoData.headerButtonColor"
                              />
                            </v-chip>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">Footer Call-To-Action:</span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Name
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData.footerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Link
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData.footerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button On/Off
                              <v-switch
                                v-model="promoData.footerButton"
                                color="primary"
                                class="ml-3"
                              ></v-switch>
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Color
                              <input
                                type="color"
                                id="head"
                                name="header button color"
                                class="ml-3"
                                v-model="promoData.footerButtonColor"
                              />
                            </v-chip>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-2 col-lg-2"></div> -->
              </div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-xl-nowrap">
                <div class="col-12 col-lg-11 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-0 pa-md-3 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Interval Message: </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12">
                        <div style="background-color: white">
                          <vue-editor
                            v-model="promoData.intervalMessage"
                            background-color="white"
                            placeholder="Interval Message (500 Character Limit)"
                            class="mb-3"
                          />
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-2 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Page Ads: </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12 col-lg-9">
                        <v-chip
                          class="ma-2"
                          label
                          style="background-color: #463f57; color: #fff"
                        >
                          Page 3 of Promo eBook
                          <v-switch
                            v-model="promoData.pageAd1"
                            color="primary"
                            class="ml-3"
                          ></v-switch>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :disabled="!promoData.pageAd1"
                                v-on="on"
                                color="white"
                                icon
                                depressed
                                small
                                @click="pageAd1Dialog = true"
                              >
                                <v-icon>mdi-note-edit-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Click to open</span>
                          </v-tooltip>
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap mb-3"
              >
                <div class="caption mb-2">Hide these from pdf's/ebooks:</div>

                <div class="d-flex gap-15 flex-wrap flex-lg-nowrap">
                  <v-switch
                    label="Hide Nutrition Info"
                    v-model="promoData.hideEnergy"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Meal Tags"
                    v-model="promoData.hideMealTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Nutrition Tags"
                    v-model="promoData.hideNutriTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Recipe Customization"
                    v-model="promoData.hideCustomization"
                    hide-details=""
                    class="mt-0 pt-0"
                    dense
                  >
                  </v-switch>
                </div>
              </div>
              <div class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap">
                <div class="caption mb-2">Recipe eBook download available every:</div>

                <div class="d-flex justify-start justify-md-center justify-lg-center flex-wrap gap-12">
                  <div>
                    <v-select
                      dense
                      outlined
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      placeholder="1"
                      v-model="promoData.dateNumber"
                    ></v-select>
                  </div>
                  <div>
                    <v-select
                      outlined
                      dense
                      placeholder="week"
                      :items="['day', 'week', 'month', 'year']"
                      v-model="promoData.dateName"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap justify-space-between"
              >
                <div class="caption mb-2">
                  Please list any diet preferences i.e. low carb or gluten-free:
                </div>
                <div class="d-flex" style="width: 100%">
                  <v-sheet width="350" color="transparent">
                    <v-combobox
                      placeholder="PLEASE PRESS ENTER AFTER EACH DIETARY PREFERENCE BEFORE ADDING THE NEXT"
                      v-model="promoData.tags"
                      class="meal-tags align-start"
                      append-icon=""
                      deletable-chips
                      @change="($event) => filterNutriTags($event, 1)"
                      width="100%"
                      small-chips
                      multiple
                      outlined
                      chips
                      dense
                    ></v-combobox>
                  </v-sheet>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
              >
                <div class="caption mb-2">
                  Set a Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-switch
                    v-model="promoData.setCalorieLimit"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData?.setCalorieLimit"
              >
                <div class="caption mb-2">
                  Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-text-field
                    :rules="[
                      (value) =>
                        [
                          'dietitian',
                          'personal_trainer',
                          'nutritionist',
                          'naturopath',
                        ].includes(user.role) ||
                        (!value && value <= 0) ||
                        value >= 5000 ||
                        `Value can't be less than 5000kJ`,
                    ]"
                    type="number"
                    v-model.number="promoData.calorieLimit"
                    suffix="kJ"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    :value="Math.round((promoData.calorieLimit || 0) / 4.18)"
                    @input="promoData.calorieLimit = $event * 4.18"
                    type="number"
                    suffix="Cal"
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Promo Recipe eBook 2</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-lg-nowrap">
                <div class="col-12 col-lg-11 px-0">
                  <v-card class="my-0 my-md-5 my-lg-5 box-shadow primary pa-1 pa-md-5 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Ebook Title Page: </span>
                    <span class="ribbon-button" v-if="lgUp">
                      <v-btn
                        class="mt-2"
                        @click="copyRecipeLink(2, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Client Questionnaire Link for Promo Recipe eBook 2
                      </v-btn>
                    </span>
                    <span class="ribbon-button-sm" v-else>
                      <v-btn
                        class="mt-2 mr-5"
                        @click="copyRecipeLink(1, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Copy Link
                      </v-btn>
                    </span>
                    <div class="d-flex gap-1 align-start justify-start justify-md-center justify-lg-center flex-wrap">
                      <v-chip
                        class="ma-1 ma-lg-2"
                        label
                        style="background-color: #463f57; color: #fff"
                      >
                        Business Name
                      </v-chip>
                      <v-text-field
                        class="mr-2"
                        v-model="promoData2.businessName"
                        outlined
                        dense
                        background-color="white"
                      >
                      </v-text-field>
                      <v-btn
                        depressed
                        icon
                        @click="
                          () => {
                            previewTitleDialog2 = true;
                            itemToShow = 'pageTitle';
                          }
                        "
                        :disabled="!promoData2?.businessName || !promoData2.bio"
                      >
                        <v-icon v-if="mdUp" large style="color: #463f57"> mdi-eye </v-icon>
                      </v-btn>
                    </div>
                    <v-chip
                      class="ma-2"
                      label
                      style="background-color: #463f57; color: #fff"
                    >
                      Page 1 of Promo eBook
                    </v-chip>
                    <div style="background-color: white">
                      <vue-editor
                        v-model="promoData2.bio"
                        background-color="white"
                        placeholder="(500 Character Limit)"
                        class="mb-3"
                      />
                    </div>
                  </v-card>
                </div>
              </div>
              <div class="d-flex gap-1 px-0 px-md-5 px-lg-8 align-start flex-sm-wrap flex-lg-nowrap">
                <div class="col-12 col-md-10 col-lg-11 col-xl-10">
                  <div
                    class="d-flex flex-wrap flex-xl-nowrap align-start justify-center gap-3"
                  >
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">Header Call-To-Action:</span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button Name
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData2.headerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button Link
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData2.headerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button On/Off
                              <v-switch
                                v-model="promoData2.headerButton"
                                color="primary"
                                class="ml-3"
                              ></v-switch>
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="mr-0 mr-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button Color
                              <input
                                type="color"
                                id="head"
                                name="header button color"
                                class="ml-3"
                                v-model="promoData2.headerButtonColor"
                              />
                            </v-chip>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">Footer Call-To-Action:</span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Name
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData2.footerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Link
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData2.footerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button On/Off
                              <v-switch
                                v-model="promoData2.footerButton"
                                color="primary"
                                class="ml-3"
                              ></v-switch>
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Color
                              <input
                                type="color"
                                id="head"
                                name="header button color"
                                class="ml-3"
                                v-model="promoData2.footerButtonColor"
                              />
                            </v-chip>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-4 col-sm-12 col-lg-2"></div> -->
              </div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-xl-nowrap">
                <div class="col-12 col-lg-11 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-0 pa-md-3 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Interval Message: </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12">
                        <div style="background-color: white">
                          <vue-editor
                            v-model="promoData2.intervalMessage"
                            background-color="white"
                            placeholder="Interval Message (500 Character Limit)"
                            class="mb-3"
                          />
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-2 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Page Ads: </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12 col-lg-9">
                        <v-chip
                          class="ma-2"
                          label
                          style="background-color: #463f57; color: #fff"
                        >
                          Page 3 of Promo eBook
                          <v-switch
                            v-model="promoData2.pageAd1"
                            color="primary"
                            class="ml-3"
                          ></v-switch>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :disabled="!promoData2.pageAd1"
                                v-on="on"
                                color="white"
                                icon
                                depressed
                                small
                                @click="pageAd2Dialog = true"
                              >
                                <v-icon>mdi-note-edit-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Click to open</span>
                          </v-tooltip>
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap mb-3"
              >
                <div class="caption mb-2">Hide these from pdf's/ebooks:</div>

                <div class="d-flex gap-15 flex-wrap flex-lg-nowrap">
                  <v-switch
                    label="Hide Nutrition Info"
                    v-model="promoData2.hideEnergy"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Meal Tags"
                    v-model="promoData2.hideMealTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Nutrition Tags"
                    v-model="promoData2.hideNutriTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Recipe Customization"
                    v-model="promoData2.hideCustomization"
                    hide-details=""
                    class="mt-0 pt-0"
                    dense
                  >
                  </v-switch>
                </div>
              </div>
              <div class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap">
                <div class="caption mb-2">Recipe eBook download available every:</div>

                <div class="d-flex justify-start justify-md-center justify-lg-center flex-wrap gap-12">
                  <div>
                    <v-select
                      dense
                      outlined
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      placeholder="1"
                      v-model="promoData2.dateNumber"
                    ></v-select>
                  </div>
                  <div>
                    <v-select
                      outlined
                      dense
                      placeholder="week"
                      :items="['day', 'week', 'month', 'year']"
                      v-model="promoData2.dateName"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap justify-space-between"
              >
                <div class="caption mb-2">
                  Please list any diet preferences i.e. low carb or gluten-free:
                </div>
                <div class="d-flex" style="width: 100%">
                  <v-sheet width="350" color="transparent">
                    <v-combobox
                      placeholder="PLEASE PRESS ENTER AFTER EACH DIETARY PREFERENCE BEFORE ADDING THE NEXT"
                      v-model="promoData2.tags"
                      class="meal-tags align-start"
                      append-icon=""
                      deletable-chips
                      @change="($event) => filterNutriTags($event, 2)"
                      width="100%"
                      small-chips
                      multiple
                      outlined
                      chips
                      dense
                    ></v-combobox>
                  </v-sheet>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
              >
                <div class="caption mb-2">
                  Set a Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-switch
                    v-model="promoData2.setCalorieLimit"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData2?.setCalorieLimit"
              >
                <div class="caption mb-2">
                  Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-text-field
                    :rules="[
                      (value) =>
                        [
                          'dietitian',
                          'personal_trainer',
                          'nutritionist',
                          'naturopath',
                        ].includes(user.role) ||
                        (!value && value <= 0) ||
                        value >= 5000 ||
                        `Value can't be less than 5000kJ`,
                    ]"
                    type="number"
                    v-model.number="promoData2.calorieLimit"
                    suffix="kJ"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    :value="Math.round((promoData2.calorieLimit || 0) / 4.18)"
                    @input="promoData2.calorieLimit = $event * 4.18"
                    type="number"
                    suffix="Cal"
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Promo Recipe eBook 3</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-lg-nowrap">
                <div class="col-12 col-lg-11 px-0">
                  <v-card class="my-0 my-md-5 my-lg-5 box-shadow primary pa-1 pa-md-5 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Ebook Title Page: </span>
                    <span class="ribbon-button" v-if="lgUp">
                      <v-btn
                        class="mt-2"
                        @click="copyRecipeLink(3, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Client Questionnaire Link for Promo Recipe eBook 3
                      </v-btn>
                    </span>
                    <span class="ribbon-button-sm" v-else>
                      <v-btn
                        class="mt-2 mr-5"
                        @click="copyRecipeLink(1, true)"
                        color="primary"
                        x-small
                        depressed
                      >
                        <v-icon class="mr-1" x-small>mdi-content-copy</v-icon>
                        Copy Link
                      </v-btn>
                    </span>
                    <div class="d-flex gap-1 align-start justify-start justify-md-center justify-lg-center flex-wrap">
                      <v-chip
                        class="ma-1 ma-lg-2"
                        label
                        style="background-color: #463f57; color: #fff"
                      >
                        Business Name
                      </v-chip>
                      <v-text-field
                        class="mr-2"
                        v-model="promoData3.businessName"
                        outlined
                        dense
                        background-color="white"
                      >
                      </v-text-field>
                      <v-btn
                        depressed
                        icon
                        @click="
                          () => {
                            previewTitleDialog3 = true;
                            itemToShow = 'pageTitle';
                          }
                        "
                        :disabled="!promoData3?.businessName || !promoData.bio"
                      >
                        <v-icon v-if="mdUp" large style="color: #463f57"> mdi-eye </v-icon>
                      </v-btn>
                    </div>
                    <v-chip
                      class="ma-2"
                      label
                      style="background-color: #463f57; color: #fff"
                    >
                      Page 1 of Promo eBook
                    </v-chip>
                    <div style="background-color: white">
                      <vue-editor
                        v-model="promoData3.bio"
                        background-color="white"
                        placeholder="(500 Character Limit)"
                        class="mb-3"
                      />
                    </div>
                  </v-card>
                </div>
                <!-- <div class="col-12 col-md-4 col-lg-2">
        <v-card
          class="my-5 box-shadow primary pa-5 ribbon-container text-center"
          outlined
        >
          <span class="ribbon-name">QR Code: </span>
          <div>
            <qrcode-vue :value="generatedLink" :size="size" level="M" id="qr-code" />
          </div>
          <v-btn @click="printQR" x-small depressed color="info"> Download </v-btn>
        </v-card>
      </div> -->
              </div>
              <div class="d-flex gap-1 px-0 px-md-5 px-lg-8 align-start flex-sm-wrap flex-lg-nowrap">
                <div class="col-12 col-md-10 col-lg-11 col-xl-10">
                  <div
                    class="d-flex flex-wrap flex-xl-nowrap align-start justify-center gap-3"
                  >
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">Header Call-To-Action:</span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button Name
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData3.headerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button Link
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData3.headerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button On/Off
                              <v-switch
                                v-model="promoData3.headerButton"
                                color="primary"
                                class="ml-3"
                              ></v-switch>
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="mr-0 mr-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Header Button Color
                              <input
                                type="color"
                                id="head"
                                name="header button color"
                                class="ml-3"
                                v-model="promoData3.headerButtonColor"
                              />
                            </v-chip>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <div class="col-12 col-lg-10 col-xl-6">
                      <v-card
                        class="my-5 box-shadow primary pa-2 pa-md-5 pa-lg-5 ribbon-container"
                        outlined
                      >
                        <span class="ribbon-name">Footer Call-To-Action:</span>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Name
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData3.footerButtonName"
                              outlined
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-4">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Link
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-8">
                            <v-text-field
                              class="mr-0 mr-lg-2"
                              v-model="promoData3.footerButtonLink"
                              outlined
                              hint="Please add 'http://' in the front"
                              dense
                              background-color="white"
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="d-flex align-start justify-start justify-md-center justify-lg-center flex-wrap flex-lg-nowrap">
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button On/Off
                              <v-switch
                                v-model="promoData3.footerButton"
                                color="primary"
                                class="ml-3"
                              ></v-switch>
                            </v-chip>
                          </div>
                          <div class="col-12 col-lg-6">
                            <v-chip
                              class="ma-0 ma-lg-2"
                              label
                              style="background-color: #463f57; color: #fff"
                            >
                              Footer Button Color
                              <input
                                type="color"
                                id="head"
                                name="header button color"
                                class="ml-3"
                                v-model="promoData3.footerButtonColor"
                              />
                            </v-chip>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-4 col-sm-12 col-lg-2"></div> -->
              </div>
              <div class="d-flex gap-1 align-start px-0 px-md-5 px-lg-8 flex-wrap flex-xl-nowrap">
                <div class="col-12 col-lg-11 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-0 pa-md-3 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Interval Message: </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12">
                        <div style="background-color: white">
                          <vue-editor
                            v-model="promoData3.intervalMessage"
                            background-color="white"
                            placeholder="Interval Message (500 Character Limit)"
                            class="mb-3"
                          />
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-5">
                  <v-card class="my-5 box-shadow primary pa-2 pa-lg-5 ribbon-container" outlined>
                    <span class="ribbon-name">Page Ads: </span>
                    <div class="d-flex gap-1 align-start justify-start">
                      <div class="col-12 col-lg-9">
                        <v-chip
                          class="ma-2"
                          label
                          style="background-color: #463f57; color: #fff"
                        >
                          Page 3 of Promo eBook
                          <v-switch
                            v-model="promoData3.pageAd1"
                            color="primary"
                            class="ml-3"
                          ></v-switch>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :disabled="!promoData3.pageAd1"
                                v-on="on"
                                color="white"
                                icon
                                depressed
                                small
                                @click="pageAd3Dialog = true"
                              >
                                <v-icon>mdi-note-edit-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Click to open</span>
                          </v-tooltip>
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap mb-3"
              >
                <div class="caption mb-2">Hide these from pdf's/ebooks:</div>

                <div class="d-flex gap-15 flex-wrap flex-lg-nowrap">
                  <v-switch
                    label="Hide Nutrition Info"
                    v-model="promoData3.hideEnergy"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Meal Tags"
                    v-model="promoData3.hideMealTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Nutrition Tags"
                    v-model="promoData3.hideNutriTags"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    label="Hide Recipe Customization"
                    v-model="promoData3.hideCustomization"
                    hide-details=""
                    class="mt-0 pt-0"
                    dense
                  >
                  </v-switch>
                </div>
              </div>
              <div class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap">
                <div class="caption mb-2">Recipe eBook download available every:</div>

                <div class="d-flex justify-start justify-md-center justify-lg-center flex-wrap gap-12">
                  <div>
                    <v-select
                      dense
                      outlined
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      placeholder="1"
                      v-model="promoData3.dateNumber"
                    ></v-select>
                  </div>
                  <div>
                    <v-select
                      outlined
                      dense
                      placeholder="week"
                      :items="['day', 'week', 'month', 'year']"
                      v-model="promoData3.dateName"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-wrap flex-lg-nowrap justify-space-between"
              >
                <div class="caption mb-2">
                  Please list any diet preferences i.e. low carb or gluten-free:
                </div>
                <div class="d-flex" style="width: 100%">
                  <v-sheet width="350" color="transparent">
                    <v-combobox
                      placeholder="PLEASE PRESS ENTER AFTER EACH DIETARY PREFERENCE BEFORE ADDING THE NEXT"
                      v-model="promoData3.tags"
                      class="meal-tags align-start"
                      append-icon=""
                      deletable-chips
                      @change="($event) => filterNutriTags($event, 3)"
                      width="100%"
                      small-chips
                      multiple
                      outlined
                      chips
                      dense
                    ></v-combobox>
                  </v-sheet>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
              >
                <div class="caption mb-2">
                  Set a Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-switch
                    v-model="promoData3.setCalorieLimit"
                    class="mt-0 pt-0"
                    hide-details
                    dense
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex gap-20 align-start px-10 flex-sm-wrap flex-lg-nowrap justify-start"
                v-if="promoData3?.setCalorieLimit"
              >
                <div class="caption mb-2">
                  Calorie limit:
                </div>
                <div class="d-flex gap-15">
                  <v-text-field
                    :rules="[
                      (value) =>
                        [
                          'dietitian',
                          'personal_trainer',
                          'nutritionist',
                          'naturopath',
                        ].includes(user.role) ||
                        (!value && value <= 0) ||
                        value >= 5000 ||
                        `Value can't be less than 5000kJ`,
                    ]"
                    type="number"
                    v-model.number="promoData3.calorieLimit"
                    suffix="kJ"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    :value="Math.round((promoData3.calorieLimit || 0) / 4.18)"
                    @input="promoData3.calorieLimit = $event * 4.18"
                    type="number"
                    suffix="Cal"
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <div class="d-flex gap-20 align-start flex-sm-wrap flex-lg-nowrap mt-8 mb-5">
      <v-btn @click="submitData" :loading="submitting" class="info" depressed
        >Save Details</v-btn
      >
    </div>
    <!-- <div class="d-flex gap-1 align-start px-8 flex-wrap flex-lg-nowrap">
      <div class="col-12">
        <v-card class="my-5 box-shadow pa-5 ribbon-container" outlined>
          <span class="ribbon-name">Client List</span>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              single-line
              hide-details
              background-color="white"
            ></v-text-field>

            <vue-json-to-csv :json-data="clients">
              <v-btn small class="ml-3 info" depressed>
                CSV <v-icon>mdi-download</v-icon>
              </v-btn>
            </vue-json-to-csv>
          </v-card-title>
          <v-data-table :headers="headers" :items="clients" :search="search">
            <template v-slot:[`item.link`]="{ item }">
              <v-btn depressed x-small class="primary mr-2" @click="goToLink(item.link)">
                Ebook Link
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div> -->
    <v-dialog v-model="pageAd1Dialog"  max-width="900" scrollable>
      <close-button @click="pageAd1Dialog = false" overflow />
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="d-flex gap-1 align-start justify-center">
            <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
              Page Title
            </v-chip>
            <v-text-field
              class="mr-2"
              v-model="pageAd1Data.pageTitle"
              outlined
              dense
              background-color="white"
            >
            </v-text-field>
            <v-btn
              depressed
              icon
              @click="
                () => {
                  previewTitleDialog = true;
                  itemToShow = 'pageAd';
                }
              "
              :disabled="
                !pageAd1Data?.pageTitle ||
                !pageAd1Data.pageContent ||
                !pageAd1Data.actionName ||
                !pageAd1Data.actionLink ||
                !pageAd1Data.actionButton ||
                !pageAd1Data.actionLink
              "
            >
              <v-icon large style="color: #463f57"> mdi-eye </v-icon>
            </v-btn>
          </div>
          <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
            Page Content
          </v-chip>
          <div style="background-color: white">
            <vue-editor
              v-model="pageAd1Data.pageContent"
              background-color="white"
              placeholder="Page Content (500 Character Limit)"
              class="mb-3"
            />
          </div>
          <div class="d-flex align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Name
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd1Data.actionName"
                outlined
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Link
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd1Data.actionLink"
                outlined
                hint="Please add 'http://' in the front"
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action On/Off
                <v-switch
                  v-model="pageAd1Data.actionButton"
                  color="primary"
                  class="ml-3"
                ></v-switch>
              </v-chip>
            </div>
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Button Color
                <input
                  type="color"
                  id="head"
                  name="header button color"
                  class="ml-3"
                  v-model="pageAd1Data.actionButtonColor"
                />
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="pageAd1Dialog = false" class="primary" depressed
            >Add Details</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pageAd2Dialog" max-width="900" scrollable>
      <close-button @click="pageAd2Dialog = false" overflow />
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="d-flex gap-1 align-start justify-center">
            <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
              Page Title
            </v-chip>
            <v-text-field
              class="mr-2"
              v-model="pageAd2Data.pageTitle"
              outlined
              dense
              background-color="white"
            >
            </v-text-field>
            <v-btn
              depressed
              icon
              @click="
                () => {
                  previewTitleDialog2 = true;
                  itemToShow = 'pageAd';
                }
              "
              :disabled="
                !pageAd2Data?.pageTitle ||
                !pageAd2Data.pageContent ||
                !pageAd2Data.actionName ||
                !pageAd2Data.actionLink ||
                !pageAd2Data.actionButton ||
                !pageAd2Data.actionLink
              "
            >
              <v-icon large style="color: #463f57"> mdi-eye </v-icon>
            </v-btn>
          </div>
          <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
            Page Content
          </v-chip>
          <div style="background-color: white">
            <vue-editor
              v-model="pageAd2Data.pageContent"
              background-color="white"
              placeholder="Page Content (500 Character Limit)"
              class="mb-3"
            />
          </div>
          <div class="d-flex align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Name
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd2Data.actionName"
                outlined
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Link
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd2Data.actionLink"
                outlined
                hint="Please add 'http://' in the front"
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action On/Off
                <v-switch
                  v-model="pageAd2Data.actionButton"
                  color="primary"
                  class="ml-3"
                ></v-switch>
              </v-chip>
            </div>
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Button Color
                <input
                  type="color"
                  id="head"
                  name="header button color"
                  class="ml-3"
                  v-model="pageAd2Data.actionButtonColor"
                />
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="pageAd2Dialog = false" class="primary" depressed
            >Add Details</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pageAd3Dialog" max-width="900" scrollable>
      <close-button @click="pageAd3Dialog = false" overflow />
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="d-flex gap-1 align-start justify-center">
            <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
              Page Title
            </v-chip>
            <v-text-field
              class="mr-2"
              v-model="pageAd3Data.pageTitle"
              outlined
              dense
              background-color="white"
            >
            </v-text-field>
            <v-btn
              depressed
              icon
              @click="
                () => {
                  previewTitleDialog3 = true;
                  itemToShow = 'pageAd';
                }
              "
              :disabled="
                !pageAd3Data?.pageTitle ||
                !pageAd3Data.pageContent ||
                !pageAd3Data.actionName ||
                !pageAd3Data.actionLink ||
                !pageAd3Data.actionButton ||
                !pageAd3Data.actionLink
              "
            >
              <v-icon large style="color: #463f57"> mdi-eye </v-icon>
            </v-btn>
          </div>
          <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
            Page Content
          </v-chip>
          <div style="background-color: white">
            <vue-editor
              v-model="pageAd3Data.pageContent"
              background-color="white"
              placeholder="Page Content (500 Character Limit)"
              class="mb-3"
            />
          </div>
          <div class="d-flex align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Name
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd3Data.actionName"
                outlined
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-4">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Link
              </v-chip>
            </div>
            <div class="col-8">
              <v-text-field
                class="mr-2"
                v-model="pageAd3Data.actionLink"
                outlined
                hint="Please add 'http://' in the front"
                dense
                background-color="white"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex gap-1 align-start justify-center">
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action On/Off
                <v-switch
                  v-model="pageAd3Data.actionButton"
                  color="primary"
                  class="ml-3"
                ></v-switch>
              </v-chip>
            </div>
            <div class="col-6">
              <v-chip class="ma-2" label style="background-color: #463f57; color: #fff">
                Call-To-Action Button Color
                <input
                  type="color"
                  id="head"
                  name="header button color"
                  class="ml-3"
                  v-model="pageAd3Data.actionButtonColor"
                />
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="pageAd3Dialog = false" class="primary" depressed
            >Add Details</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewTitleDialog" max-width="1000" scrollable>
      <close-button @click="previewTitleDialog = false" overflow />
      <v-card>
        <device picker>
          <v-carousel
            auto
            :continuous="false"
            :cycle="false"
            :show-arrows="true"
            show-arrows-on-hover
            :hide-delimiters="true"
            delimiter-icon="mdi-minus"
            :height="mdUp ? '900' : '800'"
          >
            <v-carousel-item v-if="itemToShow == 'pageTitle'">
              <v-sheet height="100%" class="pa-0 pt-3">
                <v-row justify="center" align="center">
                  <v-col cols="12" class="pb-0">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="end" align="end">
                        <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                          <div
                            class="font-weight-bold"
                            :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Client Name
                          </div>
                          <div class="text-caption">
                            A Recipe eBook courtesy of {{ promoData?.businessName }}
                          </div>
                        </v-col>
                        <v-col cols="4" lg="2" xl="2">
                          <v-img
                            :src="user?.customLogoUrl"
                            contain
                            height="70px"
                            width="110px"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <v-row justify="center" class="py-3 mt-2">
                        <div>
                          <v-img
                            v-if="mdUp"
                            :src="user?.customLogoUrl"
                            width="225"
                            height="225"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-img
                            v-else
                            :src="user?.customLogoUrl"
                            width="155"
                            height="155"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                      </v-row>
                      <div
                        class="py-3"
                        style="overflow: hidden auto"
                        :style="{ height: mdUp ? '385px' : '340px' }"
                      >
                        <v-row class="mt-5 px-10">
                          <v-col cols="12">
                            <div class="ql-editor" v-html="promoData?.bio"></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">Copyright 2023 ©</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item v-else>
              <v-sheet height="100%" class="pa-0">
                <v-row justify="center" align="center">
                  <v-col sm="12">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="start" align="center" class="py-2">
                        <v-col cols="12" class="text-center mr-3">
                          <div
                            class="font-weight-bold text-subtitle1"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            {{ pageAd1Data?.pageTitle }}
                          </div>
                        </v-col>
                        <v-col cols="12" class="text-center mr-3">
                          <v-btn
                            depressed
                            rounded
                            small
                            v-show="pageAd1Data?.actionButton"
                            :style="{
                              backgroundColor: pageAd1Data?.actionButtonColor,
                              color: '#fff',
                            }"
                            >{{ pageAd1Data?.actionName }}</v-btn
                          >
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <div class="py-3" style="overflow: hidden auto; height: 600px">
                        <v-row class="px-2">
                          <v-col cols="12">
                            <div
                              class="ql-editor"
                              v-html="pageAd1Data?.pageContent"
                            ></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">© 2023</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </device>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewTitleDialog2" max-width="1000" scrollable>
      <close-button @click="previewTitleDialog2 = false" overflow />
      <v-card>
        <device picker>
          <v-carousel
            auto
            :continuous="false"
            :cycle="false"
            :show-arrows="true"
            show-arrows-on-hover
            :hide-delimiters="true"
            delimiter-icon="mdi-minus"
            :height="mdUp ? '900' : '800'"
          >
            <v-carousel-item v-if="itemToShow == 'pageTitle'">
              <v-sheet height="100%" class="pa-0 pt-3">
                <v-row justify="center" align="center">
                  <v-col cols="12" class="pb-0">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="end" align="end">
                        <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                          <div
                            class="font-weight-bold"
                            :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Client Name
                          </div>
                          <div class="text-caption">
                            A Recipe eBook courtesy of {{ promoData2?.businessName }}
                          </div>
                        </v-col>
                        <v-col cols="4" lg="2" xl="2">
                          <v-img
                            :src="user?.customLogoUrl"
                            contain
                            height="70px"
                            width="110px"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <v-row justify="center" class="py-3 mt-2">
                        <div>
                          <v-img
                            v-if="mdUp"
                            :src="user?.customLogoUrl"
                            width="225"
                            height="225"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-img
                            v-else
                            :src="user?.customLogoUrl"
                            width="155"
                            height="155"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                      </v-row>
                      <div
                        class="py-3"
                        style="overflow: hidden auto"
                        :style="{ height: mdUp ? '385px' : '340px' }"
                      >
                        <v-row class="mt-5 px-10">
                          <v-col cols="12">
                            <div class="ql-editor" v-html="promoData2?.bio"></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">Copyright 2023 ©</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item v-else>
              <v-sheet height="100%" class="pa-0">
                <v-row justify="center" align="center">
                  <v-col sm="12">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="start" align="center" class="py-2">
                        <v-col cols="12" class="text-center mr-3">
                          <div
                            class="font-weight-bold text-subtitle1"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            {{ pageAd2Data?.pageTitle }}
                          </div>
                        </v-col>
                        <v-col cols="12" class="text-center mr-3">
                          <v-btn
                            depressed
                            rounded
                            small
                            v-show="pageAd2Data?.actionButton"
                            :style="{
                              backgroundColor: pageAd2Data?.actionButtonColor,
                              color: '#fff',
                            }"
                            >{{ pageAd2Data?.actionName }}</v-btn
                          >
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <div class="py-3" style="overflow: hidden auto; height: 600px">
                        <v-row class="px-2">
                          <v-col cols="12">
                            <div
                              class="ql-editor"
                              v-html="pageAd2Data?.pageContent"
                            ></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">© 2023</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </device>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewTitleDialog3" max-width="1000" scrollable>
      <close-button @click="previewTitleDialog3 = false" overflow />
      <v-card>
        <device picker>
          <v-carousel
            auto
            :continuous="false"
            :cycle="false"
            :show-arrows="true"
            show-arrows-on-hover
            :hide-delimiters="true"
            delimiter-icon="mdi-minus"
            :height="mdUp ? '900' : '800'"
          >
            <v-carousel-item v-if="itemToShow == 'pageTitle'">
              <v-sheet height="100%" class="pa-0 pt-3">
                <v-row justify="center" align="center">
                  <v-col cols="12" class="pb-0">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="end" align="end">
                        <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                          <div
                            class="font-weight-bold"
                            :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Client Name
                          </div>
                          <div class="text-caption">
                            A Recipe eBook courtesy of {{ promoData3?.businessName }}
                          </div>
                        </v-col>
                        <v-col cols="4" lg="2" xl="2">
                          <v-img
                            :src="user?.customLogoUrl"
                            contain
                            height="70px"
                            width="110px"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <v-row justify="center" class="py-3 mt-2">
                        <div>
                          <v-img
                            v-if="mdUp"
                            :src="user?.customLogoUrl"
                            width="225"
                            height="225"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-img
                            v-else
                            :src="user?.customLogoUrl"
                            width="155"
                            height="155"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  :color="user?.brandColor ? user.brandColor : '#97c81f'"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                      </v-row>
                      <div
                        class="py-3"
                        style="overflow: hidden auto"
                        :style="{ height: mdUp ? '385px' : '340px' }"
                      >
                        <v-row class="mt-5 px-10">
                          <v-col cols="12">
                            <div class="ql-editor" v-html="promoData3?.bio"></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">Copyright 2023 ©</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                        <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item v-else>
              <v-sheet height="100%" class="pa-0">
                <v-row justify="center" align="center">
                  <v-col sm="12">
                    <v-card
                      class="px-3 custom-card"
                      :style="{ height: mdUp ? '830px' : '800px' }"
                    >
                      <v-row justify="start" align="center" class="py-2">
                        <v-col cols="12" class="text-center mr-3">
                          <div
                            class="font-weight-bold text-subtitle1"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            {{ pageAd3Data?.pageTitle }}
                          </div>
                        </v-col>
                        <v-col cols="12" class="text-center mr-3">
                          <v-btn
                            depressed
                            rounded
                            small
                            v-show="pageAd3Data?.actionButton"
                            :style="{
                              backgroundColor: pageAd3Data?.actionButtonColor,
                              color: '#fff',
                            }"
                            >{{ pageAd3Data?.actionName }}</v-btn
                          >
                        </v-col>
                      </v-row>
                      <div class="mt-3 px-8">
                        <v-divider />
                      </div>
                      <div class="py-3" style="overflow: hidden auto; height: 600px">
                        <v-row class="px-2">
                          <v-col cols="12">
                            <div
                              class="ql-editor"
                              v-html="pageAd3Data?.pageContent"
                            ></div>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        class="bg"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      />
                      <div class="text-caption bg-text">© 2023</div>
                      <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                        <a v-if="user.facebook" :href="user.facebook" target="_blank">
                          <v-icon medium color="white"> mdi-facebook </v-icon>
                        </a>
                        <a v-if="user.website" :href="user.website" target="_blank">
                          <v-icon medium color="white"> mdi-web </v-icon>
                        </a>
                        <a v-if="user.instagram" :href="user.instagram" target="_blank">
                          <v-icon medium color="white"> mdi-instagram </v-icon>
                        </a>
                      </div>
                      <div
                        v-if="user?.isStudent"
                        class="text-body2 red--text sample-text"
                      >
                        *This eBook was created by a student
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </device>
      </v-card>
    </v-dialog>

    <v-dialog v-model="videoDialog" width="1200" eager>
      <close-button @click="videoDialog = false" overflow />
      <v-card>
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/956833351?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
            title="MEALZEE How To.mp4"
          ></iframe>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import QrcodeVue from "qrcode.vue";
import { VueEditor } from "vue2-editor";
import { goals } from "@/options";
import Device from "vue-device";

import { nutriTagsKeywords } from "@/options"

// import VueJsonToCsv from "vue-json-to-csv";

import { mapState, mapActions } from "vuex";

export default {
  name: "PromoEbook",
  data() {
    return {
      goals,
      size: 180,
      search: "",
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Age",
          value: "age",
        },
        {
          text: "Goal",
          value: "goal",
        },
        {
          text: "BMI (kg/m2)",
          value: "bmi",
        },
        {
          text: "PAL",
          value: "pal",
        },
        {
          text: "Meal Pattern",
          value: "mealPattern",
        },
        {
          text: "Meal Tags",
          value: "mealTags",
        },
        {
          text: "Nutrition Tags",
          value: "nutritionTags",
        },
        {
          text: "Excluded Ingredients",
          value: "ingredientTags",
        },
        {
          text: "Ebook Link",
          align: "start",
          value: "link",
        },
      ],
      promoData: {
        tags: [],
      },
      promoData2: {
        tags: [],
      },
      promoData3: {
        tags: [],
      },
      itemToShow: "",
      pageAd1Data: {},
      pageAd2Data: {},
      pageAd3Data: {},
      submitting: false,
      videoDialog: false,
      pageAd1Dialog: false,
      pageAd2Dialog: false,
      pageAd3Dialog: false,
      previewTitleDialog: false,
      previewTitleDialog2: false,
      previewTitleDialog3: false,
      nutriTagsKeywords,
    };
  },
  components: {
    Device,
    // QrcodeVue,
    VueEditor,
    // VueJsonToCsv,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      allPlans: (state) => state.mealplans.plans,
      allFolders: (state) => state.mealplans.folders,
    }),
    generatedLink() {
      // return `${window.origin}/promoform/${this.removeSpace(
      //   this.user.firstName + this.user.lastName
      // )}?i=${this.user.id}`;
      return `https://mealguide.pro/promoform/${this.removeSpace(
        this.user.firstName + this.user.lastName
      )}?i=${this.user.id}`;
    },

    folders() {
      return this.allFolders.filter((folder) => folder?.promo);
    },

    mealplans() {
      return this.allPlans.filter((plan) => plan?.promo);
    },

    clients() {
      if (!this.folders.length) return [];

      if (!this.mealplans.length) return [];

      return this.folders.map((folder) => {
        let mealplan = this.mealplans.find((p) => p.folderId == folder?.id);
        const link = `${window.origin}/promoebookpage/${this.removeSpace(
          `${this.user.firstName}${this.user.lastName}`
        )}/${this.removeSpace(folder.client.replace(/#/gi, ""))}?i=${mealplan?.id}`;

        let mealTags = [];
        let mealPattern = [];
        let nutritionTags = [];
        let ingredientTags = [];

        if (folder?.mainMeals) mealPattern = [...folder?.mainMeals];

        if (folder?.snacks) {
          for (let s = 1; s <= folder?.snacks; s++) {
            mealPattern.push(`Snack ${s}`);
          }
        }

        if (mealplan?.mealTags)
          mealTags = mealplan.mealTags.map((t) => {
            if (this.$store.getters["tags/checkIfUnpublish"](t));
            return this.$store.getters["tags/getName"](t);
          });

        if (mealplan?.nutritionTags)
          nutritionTags = mealplan.nutritionTags.map((t) => {
            if (this.$store.getters["nutritiontags/checkIfUnpublish"](t))
              return this.$store.getters["nutritiontags/tagData"](t).name;
          });

        if (mealplan?.ingredientTags) ingredientTags = mealplan.ingredientTags;

        return {
          link,
          name: folder.client,
          email: folder.email,
          age: folder.age,
          goal: this.getGoalValue(folder.goal).text,
          bmi: Math.round(
            folder.weight / ((folder.height / 100) * (folder.height / 100))
          ),
          pal: folder.physicalActivityLevel.name,
          mealPattern,
          mealTags,
          nutritionTags,
          ingredientTags,
        };
      });
    },

    findEmail() {
      return this.allFolders.filter((f) => f.email == "csh267@gmail.com");
    },
    lgUp: function () {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    mdUp: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    smDown: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions("mealplans", ["getPlans", "getFolders"]),

    removeSpace(text) {
      return text.replace(/\s/g, "%20");
    },

    async submitData() {
      this.submitting = true;

      try {
        await Promise.resolve(
          this.$store.dispatch("user/updateUserField", {
            value: this.promoData,
            field: "promoData",
            silent: true,
          })
        );

        await Promise.resolve(
          this.$store.dispatch("user/updateUserField", {
            value: this.promoData2,
            field: "promoData2",
            silent: true,
          })
        );

        await Promise.resolve(
          this.$store.dispatch("user/updateUserField", {
            value: this.promoData3,
            field: "promoData3",
            silent: true,
          })
        );

        if (this.promoData?.pageAd1)
          await Promise.resolve(
            this.$store.dispatch("user/updateUserField", {
              value: this.pageAd1Data,
              field: "pageAd1Data",
              silent: true,
            })
          );
        if (this.promoData2?.pageAd1)
          await Promise.resolve(
            this.$store.dispatch("user/updateUserField", {
              value: this.pageAd2Data,
              field: "pageAd2Data",
              silent: true,
            })
          );
        if (this.promoData3?.pageAd1)
          await Promise.resolve(
            this.$store.dispatch("user/updateUserField", {
              value: this.pageAd3Data,
              field: "pageAd3Data",
              silent: true,
            })
          );

        this.$store.dispatch("showSuccess", "Success! Details saved!");
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },

    printQR() {
      const link = document.createElement("a");
      link.download = `qr-code.png`;
      link.href = document.getElementById("qr-code").childNodes[0].toDataURL();
      link.click();
    },
    getGoalValue(val) {
      return this.goals.find((g) => g.value == val);
    },
    goToLink(link) {
      console.log(link);
      setTimeout(() => {
        window.open(link, "_blank");
      });
    },
    copyRecipeLink(promoPage, autoGen) {
      const link = `https://mealguide.pro/promoform/${this.removeSpace(
        this.user.firstName + this.user.lastName
      )}?i=${this.user.id}&promopage=${promoPage}&autogen=${autoGen}`;

      // const link =  `${window.origin}/promoform/${this.removeSpace(
      //   this.user.firstName + this.user.lastName
      // )}?i=${this.user.id}&promopage=${promoPage}&autogen=${autoGen}`;

      navigator.clipboard.writeText(this.removeSpace(link));
      this.$store.dispatch("showSuccess", "URL copied to clipboard");
    },
    filterNutriTags(val, promo) {
      if (!val.length) return;

      let result;
      let BreakException = {};

      // let re = new RegExp("\\b" + val[val.length - 1] +  "\\b", "i")
      let re = new RegExp(val[val.length - 1], "gi");

      this.nutriTagsKeywords.forEach((tag) => {
        result = tag.keywords.some((k) => re.test(k));

        if (result) {
          if (promo == 1) {
            this.promoData.tags.pop();

            let exist = this.promoData.tags.some((t) => t == tag.name);

            if (exist) {
              this.$store.dispatch("showSuccess", "Tag is already in used.");
            } else {
              this.promoData.tags.push(tag.name);
            }
          } else if (promo == 2) {
            this.promoData2.tags.pop();

            let exist = this.promoData2.tags.some((t) => t == tag.name);

            if (exist) {
              this.$store.dispatch("showSuccess", "Tag is already in used.");
            } else {
              this.promoData2.tags.push(tag.name);
            }
          } else {
            this.promoData3.tags.pop();

            let exist = this.promoData3.tags.some((t) => t == tag.name);

            if (exist) {
              this.$store.dispatch("showSuccess", "Tag is already in used.");
            } else {
              this.promoData3.tags.push(tag.name);
            }
          }

          throw BreakException;
        }
      });

      if (!result && !this.nutriTagsKeywords.some((t) => t.name == val[val.length - 1])) {
        this.$store.dispatch("showError", "Sorry, the selected tag is not available");

        if(promo == 1) 
          this.promoData.tags.pop();
        else if(promo == 2) 
          this.promoData2.tags.pop();
        else 
          this.promoData3.tags.pop();
      }
    },
  },

  mounted() {
    if (this.user) {
      if (this.user?.promoData) this.promoData = this.user?.promoData;

      if (this.user?.promoData2) this.promoData2 = this.user?.promoData2;

      if (this.user?.promoData3) this.promoData3 = this.user?.promoData3;

      if (this.user?.pageAd1Data) this.pageAd1Data = this.user?.pageAd1Data;
      if (this.user?.pageAd2Data) this.pageAd2Data = this.user?.pageAd2Data;
      if (this.user?.pageAd3Data) this.pageAd3Data = this.user?.pageAd3Data;
    }

    this.getPlans();
    this.getFolders();

    if (!this.$store.state.tags.status.firstLoad) this.$store.dispatch("tags/getTags");

    this.$store.dispatch("nutritiontags/getTags");
  },
};
</script>

<style scoped>
.ribbon-container {
  position: relative;
}

.ribbon-name {
  position: absolute;
  top: -25px;
  left: 0px;
  background-color: #463f57;
  color: white;
  padding: 0 1rem;
}

.ribbon-button {
  position: absolute;
  top: -33px;
  left: 17%;
  color: white;
  padding: 0 1rem;
}

.ribbon-button-sm {
  position: absolute;
  top: -33px;
  left: 50%;
  color: white;
  padding: 0 1rem;
}

#device {
  position: relative !important;
  background-color: #fff !important;
  padding: 20px;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  height: 5%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.bg-text {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 0 10px;
  color: #fff;
}

.bg-btn {
  width: 100%;
  position: absolute;
  bottom: 8px;
  left: 20%;
  padding: 0 10px;
  color: #fff;
}

.social-medias {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 0 10px;
  color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}
</style>
